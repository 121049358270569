.scheduler_green_main
{
    font-family: -apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif;
    font-size: 13px;
    border: 1px solid #999;
}

.scheduler_green_event {
    font-size:13px;
    color:#fff;
}

.scheduler_green_event_inner
{
    padding: 6px 4px 4px 4px;
    overflow: hidden;

    position: absolute;
    top: 0px;
    bottom: 1px;
    left: 0px;
    right: 0px;
    color:#666;

    background: #eeeeee;
    border: 1px solid #aaaaaa;

    display: flex; align-items: center;
}

.scheduler_green_event_bar
{
    top: 3px;
    left: 4px;
    right: 4px;
    height: 2px;
    background-color: #C7D48D;
}

.scheduler_green_event_bar_inner
{
    position: absolute;
    height: 2px;
    background-color: #06ab00;
}

.scheduler_green_event_hover .scheduler_green_event_inner
{
    background: #454645;
    color:#ffff;
    background: linear-gradient(to bottom, #646464 0%, #777676);
}

/* .scheduler_green_red is an example custom class that can be applied using BeforeEventRender handler */
.scheduler_green_red .scheduler_green_event_inner
{
    border: 1px solid #c00004;
    background: #ab0000;
    background: linear-gradient(to bottom, #ff2819 0%, #ab0000);
}


.scheduler_green_timeheader
{
    cursor: default;
    color: #a4a9fc;
}

.scheduler_green_message
{
    padding: 10px;
    opacity: 0.9;
    filter: alpha(opacity=90);
    color: #ffffff;
    text-shadow: 0px -1px 1px rgba(000,000,000,0.2), 0px 1px 0px rgba(255,255,255,0.3);

    background: #000;
    background: linear-gradient(to bottom, #999999 0%, #666666);
}

.scheduler_green_timeheadergroup,
.scheduler_green_timeheadercol {
    color: #ffffff;
    text-shadow: 0px -1px 1px rgba(000,000,000,0.2), 0px 1px 0px rgba(255,255,255,0.3);
}

.scheduler_green_timeheadergroup_inner
{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;

    border-right: 1px solid #999;
    padding: 2px;

    background: #a4a9fc;
    background: linear-gradient(to bottom, #777777 0%, #666666);
    display: flex; align-items: center; justify-content: center;
}

.scheduler_green_timeheadercol_inner
{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    border-right: 1px solid #999;
    border-top: 1px solid #999;

    padding: 2px;

    background: #a4a9fc;
    background: linear-gradient(to bottom, #777777 0%, #666666);
    display: flex; align-items: center; justify-content: center;
}

.scheduler_green_rowheader
{
    color: #ffffff;
    text-shadow: 0px -1px 1px rgba(000,000,000,0.2), 0px 1px 0px rgba(255,255,255,0.3);

    background: #666;
    background: linear-gradient(to right, #777777 0%, #666666);
}

.scheduler_green_rowheader_inner {
    padding: 7px;
    position: absolute; left: 0px; right: 0px; top: 0px; bottom: 0px; display: flex; align-items: center;
}

.scheduler_green_corner
{
    color: #ffffff;
    background: #666;
    background: linear-gradient(to right, #777777 0%, #666666);
}

.scheduler_green_resourceheader td
{
    border-bottom: 1px solid black;
    padding: 0px;
}

.scheduler_green_tree_image_no_children {}
.scheduler_green_tree_image_expand { background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J00gMS41IDAuNSBMIDYuNSA1IEwgMS41IDkuNScgc3R5bGU9J2ZpbGw6bm9uZTtzdHJva2U6Izk5OTk5OTtzdHJva2Utd2lkdGg6MjtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLWxpbmVjYXA6YnV0dCcgLz48L3N2Zz4=); }
.scheduler_green_tree_image_collapse { background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTAnIGhlaWdodD0nMTAnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTSAwLjUgMS41IEwgNSA2LjUgTCA5LjUgMS41JyBzdHlsZT0nZmlsbDpub25lO3N0cm9rZTojOTk5OTk5O3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbGluZWNhcDpidXR0JyAvPjwvc3ZnPg==); }

.scheduler_green_divider,
.scheduler_green_splitter
{
    background-color: #ccc;
}

.scheduler_green_divider_horizontal
{
    background-color: #ccc;
}

.scheduler_green_matrix_vertical_line
{
    background-color: #eee;
}

.scheduler_green_matrix_vertical_break
{
    background-color: #000;
}
.scheduler_green_matrix_horizontal_line
{
    background-color: #eee;
}

.scheduler_green_resourcedivider
{
    background-color: #ccc;
}

.scheduler_green_shadow_inner
{
    background-color: #666666;
    opacity: 0.5;
    filter: alpha(opacity=50);
    height: 100%;
    border-radius: 5px;
}
.scheduler_green_cell{
    background-color: #fff;
}

.scheduler_green_cellweekend
{
    background-color: #f0f0f0;
}

.scheduler_green_timeheader_float {
    display: flex; align-items: center; justify-content: center;
}

.scheduler_green_timeheader_float_inner {
    padding: 3px;
}

.scheduler_green_event_float {
    display: flex; align-items: center;
}

.scheduler_green_event_float_inner {
    padding: 4px;
    padding-left: 8px; position: relative;
}

.scheduler_green_event_float_inner:after {
    content:"";
    border-color: transparent #fff transparent transparent;
    border-style:solid;
    border-width:5px;
    width:0;
    height:0;
    position:absolute;
    top:7px;
    left:-4px;
}

.scheduler_green_event_move_left {
    box-sizing: border-box;
    padding: 2px;
    border: 1px solid #ccc;
    background: #fff;
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee);
}

.scheduler_green_event_move_right {
    box-sizing: border-box;
    padding: 2px;
    border: 1px solid #ccc;
    background: #fff;
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee);
}

.scheduler_green_event_delete {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjExR/NCNwAAAI5JREFUKFNtkLERgCAMRbmzdK8s4gAUlhYOYEHJEJYOYOEwDmGBPxC4kOPfvePy84MGR0RJ2N1A8H3N6DATwSQ57m2ql8NBG+AEM7D+UW+wjdfUPgerYNgB5gOLRHqhcasg84C2QxPMtrUhSqQIhg7ypy9VM2EUZPI/4rQ7rGxqo9sadTegw+UdjeDLAKUfhbaQUVPIfJYAAAAASUVORK5CYII=) center center no-repeat;
    opacity: 0.6;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=60)';
    cursor: pointer;
}

.scheduler_green_event_delete:hover {
    opacity: 1;
    -ms-filter: none;
}

.scheduler_green_rowmove_handle { background-repeat: no-repeat; background-position: center center; background-color: #ccc; background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAKCAYAAACT+/8OAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjExR/NCNwAAAClJREFUGFdj+P//P4O9vX2Bg4NDP4gNFgBytgPxebgAMsYuQGMz/jMAAFsTZDPYJlDHAAAAAElFTkSuQmCC); cursor: move; }
.scheduler_green_rowmove_source { background-color: black; opacity: 0.2; }
.scheduler_green_rowmove_position_before, .scheduler_green_rowmove_position_after { background-color: #999; height: 2px; }
.scheduler_green_rowmove_position_child { margin-left: 10px; background-color: #999; height: 2px; }
.scheduler_green_rowmove_position_child:before { content: '+'; color: #999; position: absolute; top: -8px; left: -10px; }
.scheduler_green_rowmove_position_forbidden { background-color: red; height: 2px; margin-left: 10px; }
.scheduler_green_rowmove_position_forbidden:before { content: 'x'; color: red; position: absolute; top: -8px; left: -10px; }

.scheduler_green_link_horizontal { border-bottom-style: solid; border-bottom-color: red }
.scheduler_green_link_vertical { border-right-style: solid; border-right-color: red }
.scheduler_green_link_arrow_right:before { content: ''; border-width: 6px; border-color: transparent transparent transparent red; border-style: solid; width: 0px; height:0px; position: absolute; }
.scheduler_green_link_arrow_left:before { content: ''; border-width: 6px; border-color: transparent red transparent transparent; border-style: solid; width: 0px; height:0px; position: absolute; }
.scheduler_green_link_arrow_down:before { content: ''; border-width: 6px; border-color: red transparent transparent transparent; border-style: solid; width: 0px; height:0px; position: absolute; }

.scheduler_green_shadow_overlap .scheduler_green_shadow_inner { background-color: red; }
.scheduler_green_overlay { background-color: gray; opacity: 0.5; filter: alpha(opacity=50); }

.scheduler_green_event_group { box-sizing: border-box; font-size:13px; color:#666; padding:2px 2px 2px 2px; overflow:hidden; border:1px solid #ccc; background-color: #fff; }

.scheduler_green_header_icon {
    box-sizing: border-box;
    border: 1px solid #999;
    color: #fff;
    background: #666;
    background: linear-gradient(to bottom, #777777 0%, #666666);
}
.scheduler_green_header_icon:hover { background-color: #ccc; }
.scheduler_green_header_icon_hide:before { content: '\00AB'; }
.scheduler_green_header_icon_show:before { content: '\00BB'; }

.scheduler_green_rowheader.scheduler_green_rowheader_selected { background-color: #aaa;background-image: linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent); background-size: 20px 20px; }

.scheduler_green_row_new .scheduler_green_rowheader_inner { cursor: text; background-position: 0px 5px; background-repeat: no-repeat; background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABUSURBVChTY0ACslAaK2CC0iCQDMSlECYmQFYIAl1AjFUxukIQwKoYm0IQwFCMSyEIaEJpMMClcD4Qp0CYEIBNIUzRPzAPCtAVYlWEDgyAGIdTGBgAbqEJYyjqa3oAAAAASUVORK5CYII=); }
.scheduler_green_row_new .scheduler_green_rowheader_inner:hover { background: white; }
.scheduler_green_rowheader textarea { padding: 3px; }
.scheduler_green_rowheader_scroll { cursor: default; }

.scheduler_green_shadow_forbidden .scheduler_green_shadow_inner { background-color: red; }

.scheduler_green_event_moving_source { opacity: 0.5; filter: alpha(opacity=50); }

.scheduler_green_linkpoint { background-color: white; border: 1px solid gray; border-radius: 5px; }
.scheduler_green_linkpoint.scheduler_green_linkpoint_hover { background-color: black; }

.scheduler_green_event.scheduler_green_event_version .scheduler_green_event_inner { background-color: #cfdde8; background-image: linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent); background-size: 20px 20px; }

.scheduler_green_crosshair_vertical, .scheduler_green_crosshair_horizontal, .scheduler_green_crosshair_left, .scheduler_green_crosshair_top { background-color: gray; opacity: 0.2; filter: alpha(opacity=20); }
.scheduler_green_link_dot { border-radius: 10px; background-color: red; }
.scheduler_green_task_milestone .scheduler_green_event_inner { position:absolute;top:16%;left:16%;right:16%;bottom:16%; background: #38761d; border: 0px none; transform: rotate(45deg); filter: none; }
.scheduler_green_event_left { white-space: nowrap; padding-top: 5px; color: #666; cursor: default; }
.scheduler_green_event_right { white-space: nowrap; padding-top: 5px; color: #666; cursor: default; }
.scheduler_green_selectionrectangle { background-color: #0000ff; border: 1px solid #000033; opacity: 0.4; }
.scheduler_green_link_shadow { border:1px solid black; }
.scheduler_green_link_shadow_circle { background-color:black; }

.scheduler_green_block { background-color: #808080; opacity: 0.5; }
